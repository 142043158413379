import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Media } from "../Media"

import oldLogo from "./../images/old-season/logo.png"

import imageA from "../images/old-season/season_1_a.jpg"
import imageB from "../images/old-season/season_1_b.jpg"

import Subnavigation from "./../components/sub-navigation"
import HeaderSmall from "./../components/header-small"
import FlareSeparator from "./../components/flare-separator"
import NewsLetter from "../components/newsletter"

const pageTitle = "Season 1 Results"

const IndexPage = () => {
  return (
    <Layout header={false}>
      <SEO title={pageTitle} />

      <Subnavigation />

      <article className="pt-24">
        <HeaderSmall />

        <FlareSeparator />

        <Media at="sm" className="mobileHeader">
          <div className="flex content-center  mt-8">
            <div className=" rounded-full p-4 m-auto sm:max-w-xs block max-w-xs">
              <Link to="/">
                <img className="logo w-6/12 mx-auto" src={oldLogo} alt="AGBO" />
              </Link>
            </div>
          </div>
        </Media>
        <Media greaterThanOrEqual="md" className="fullHeader">
          <div className="flex content-center  mt-8">
            <div className=" rounded-full p-4 m-auto sm:max-w-xs block max-w-xs">
              <Link to="/">
                <img
                  className="logo w-2/4 xl:w-1/2 mx-auto"
                  src={oldLogo}
                  alt="AGBO"
                />
              </Link>
            </div>
          </div>
        </Media>

        <div className="mt-8 md:w-5/6 mx-auto">
          <h1 className="text-4xl md:text-6xl text-center mt-8 md:mb-0 px-6">
            {pageTitle}
          </h1>

          <div className="mt-8 mb-16 md:w-5/6 mx-auto">
            <p className="description">
              In 2019-2020, Chris Hemsworth came out on top, with the God of
              Thunder raising $19,000 for the Australian Childhood Foundation.
              Who will emerge vicThorious this season?
            </p>
          </div>

          <iframe
            width="85%"
            height="450vh"
            src="https://www.youtube.com/embed/JNuuxe7UlHw"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="season-1-video"
            className="mx-auto"
          ></iframe>

          <div className="flex flex-col lg:flex-row mt-8 p-8 mb:p-0">
            <div className="pb-4 lg:pr-4">
              <img src={imageA} alt="Results Season 1" />
            </div>
            <div className="pb-4 lg:pl-4">
              <img src={imageB} alt="Results Season 2" />
            </div>
          </div>
        </div>
      </article>
      <NewsLetter />
    </Layout>
  )
}

export default IndexPage
