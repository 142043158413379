import React from "react"
import { Link } from "gatsby"
import { Media } from "./../Media"
import logoMobile from "../images/mobile/header/logo.png"
import logoFull from "../images/full/header/logo.png"

export default function HeaderSmall() {
  return (
    <>
      <Media at="sm" className="mobileHeader">
        <div className="flex content-center  mt-8">
          <div className=" rounded-full p-4 m-auto sm:max-w-xs block max-w-xs">
            <Link to="/">
              <img
                className="logo w-8/12 mx-auto"
                src={logoMobile}
                alt="AGBO"
              />
            </Link>
          </div>
        </div>
      </Media>
      <Media greaterThanOrEqual="md" className="fullHeader">
        <div className="flex content-center  mt-8">
          <div className=" rounded-full p-4 m-auto sm:max-w-xs block max-w-xs">
            <Link to="/">
              <img
                className="logo w-2/4 xl:w-1/4 mx-auto"
                src={logoFull}
                alt="AGBO"
              />
            </Link>
          </div>
        </div>
      </Media>
    </>
  )
}
